import React, { useEffect, useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import { useHistory } from 'react-router-dom';

const ContactoForm = () => {
  const form = useRef();
  const history = useHistory();

  const frmContact = {
    userName: '',
    userEmail: '',
    userOrganization: '',
    userWeb: '',
    emailTitle: 'Consulta Web - Portfolio',
    emailDetails: ''
  };

  const [contact, setContact] = useState(frmContact);

  const handleChange = e => {
    const {name, value} = e.target;
    setContact({...contact, [name]: value});
  };

  const handleSubmit = e => {
    e.preventDefault();

    emailjs.sendForm('service_bp6xeyx', 'template_iveeh38', form.current, 'PMHeJmWVnktNkxIX2')
        .then(response => {
          console.log('SUCCESS!', response.status, response.text);
          setContact(frmContact);
          history.push("/mailsuccess");
        }, err => {
          console.log('FAILED...', err);
          history.push("/mailerror");
        });
  };

  useEffect(() => {
    // Cargar el script de LinkedIn
    const script = document.createElement('script');
    script.src = "https://platform.linkedin.com/badges/js/profile.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      // Limpiar script al desmontar el componente
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="containerContact d-flex justify-content-center">

      <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
        <div className="container my-5">
          <h3 className="animate__animated animate__jello">
            Contacto
          </h3>
          <i className="fas fa-sort-down"></i>

          {/*<form ref={form} onSubmit={handleSubmit}>
          
          <div className="form-row">
            <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <input 
                type="text" 
                className="form-control animate__animated animate__backInLeft"
                placeholder="Tu Nombre"
                required
                value={contact.userName} 
                name="userName" 
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <input 
                type="email" 
                className="form-control animate__animated animate__backInLeft"
                placeholder="Tu Correo"
                required
                value={contact.userEmail} 
                name="userEmail" 
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <input 
                type="text" 
                className="form-control animate__animated animate__backInLeft"
                placeholder="Organización (Opcional)"
                value={contact.userOrganization}
                name="userOrganization"
                onChange={handleChange}
              />
            </div>
            <div className="form-group col-md-6">
              <input 
                type="url" 
                className="form-control animate__animated animate__backInLeft"
                placeholder="Sitio web (Opcional)"
                value={contact.userWeb} 
                name="userWeb" 
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="form-group">
            <textarea
              className="form-control animate__animated animate__backInUp"
              rows="5"
              value={contact.emailDetails} 
              name="emailDetails" 
              onChange={handleChange}
            ></textarea>
          </div>
          <button 
            type="submit" 
            className="btn btn-primary btn-block animate__animated animate__fadeInUp"
          >
            Enviar
          </button>
        </form>*/}
          <div className="card">
            <div className="card-header">
              <img
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/01/LinkedIn_Logo.svg/291px-LinkedIn_Logo.svg.png"
                  alt="LinkedIn" width={100}/>
            </div>
            <div className="card-body">
              <div>
                <img className="profile-image" alt="Carlos Soria"
                     src="https://media.licdn.com/dms/image/v2/C5603AQEQek2F3hMwEw/profile-displayphoto-shrink_200_200/profile-displayphoto-shrink_200_200/0/1516959321730?e=2147483647&amp;v=beta&amp;t=RweLM5-r5GyPxbDbiAU39P9TpZWRjI6eXRI3kTmFFiY"/>
              </div>
              <div>
                <div className="profile-name">
                  Lic. Carlos Soria
                </div>
                <div className="profile-description">
                  <p>Backend software developer</p>
                  PHP Laravel • API REST • MySQL • Docker
                  <br/>
                  Nginx • Apache
                </div>
              </div>

              <a href="https://www.linkedin.com/in/soriacarlos" target="_blank"
              className="btn btn-outline-primary mt-4">
                Ver perrfil
              </a>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactoForm;
